<template>
    <OColumn
        :field="columnData.field" 
        :format="columnData.format" 
        :headerName="columnData.headerName ? columnData.headerName : columnData.field" 
        :headerTitle="columnData.headerTitle ? columnData.headerTitle : columnData.field"
        :hide="columnData.hide"
        :class="{'text-end': columnData.type == 'Number', 'd-flex justify-content-between': columnData.useTrafficLights}" 
        :width="columnData.width"
        :type="getColumnType(columnData.type)"
        :summaryAggregate="columnData.aggregateType"
        :sortable="columnData.useSort"
    >
        <template #default="{ row, column }">
            <button v-if="columnData.urlObject?.application" type="button" class="btn btn-link btn-sm p-0" @click="urlClicked(row, columnData)">{{ row[columnData.field] }}</button>
            <span v-else-if="columnData.format">{{ utils.format(row[columnData.field], column) }}</span>
            <span v-else>{{ row[columnData.field] }}</span>

            <template v-if="columnData.useTrafficLights">
                <i v-if="columnData.greenLightConditions && columnData.greenLightConditions.length > 0 && showTrafficLight(row, columnData.greenLightConditions)" class="bi text-success bi-circle-fill ms-1"></i>
                <i v-else-if="columnData.yellowLightConditions && columnData.yellowLightConditions.length > 0 && showTrafficLight(row, columnData.yellowLightConditions)" class="bi text-warning bi-circle-fill ms-1"></i>
                <i v-else-if="columnData.redLightConditions && columnData.redLightConditions.length > 0 && showTrafficLight(row, columnData.redLightConditions)" class="bi text-danger bi-circle-fill ms-1"></i>
            </template>
        </template>
    </OColumn>
</template>

<script setup lang="ts">
    import { urlFilter } from 'o365-filterobject';
    import { utils, $t } from 'o365-utils';

    const props = defineProps({
        columnData: {
            type: Object,
            required: true,
            default: []
        },
        fields: {
            type: Object,
            required: true,
            default: {}
        }
    });

    function getColumnType(type:string){
        if(type == "Number"){
            return "number"
        }else if(type == "Date"){
            return "date"
        }else{
            return "string"
        }
    }

    function showTrafficLight(row, conditions){
        let result: boolean | null = null;
        let found: any = props.fields[conditions[0].field];
        let andGroupResult = evaluateCondition(found, row[conditions[0].field], conditions[0].operator,  checkCompareField(conditions[0].compareField, row));
        const hasOR = conditions.some(condition => condition.connector === 'OR');
        for(let i = 1; i< conditions.length; i++) {
            found = props.fields[conditions[i].field];
            const leftField = row[conditions[i].field] ?? 0;
            const rightField = checkCompareField(conditions[i].compareField, row) ?? 0;
            const {operator, connector} = conditions[i];
            const evaluation = evaluateCondition(found, leftField, operator, rightField);
            if(connector === 'AND') {
                andGroupResult = andGroupResult && evaluation;
                if(!andGroupResult && !hasOR){
                    return false;
                }
            }
            else if(connector === 'OR'){
                if(result === null){
                    result = andGroupResult;
                } else {
                    result = result || andGroupResult;
                }
                andGroupResult = evaluation
            }
        }
        
        return result || andGroupResult;
    }

    async function urlClicked(row, col){
        let url = "/"+col.urlObject.application;
        let urlParams:Array<string> = [];

        if(col.urlObject.useGridFilter){
            col.urlObject.urlFields.forEach((field:UrlField)=>{
                urlParams.push(field.urlParameter+field.operator+ (/^\d+$/.test(row[field.valueField]) ? row[field.valueField] : "'" + row[field.valueField] + "'"));
            });
            if(urlParams.length > 0){
                const resp = await urlFilter.getCompressedUrlFilterString(
                    urlParams.join(" AND "), 
                    col.urlObject.application == "scope-items" ? "scope-library" : col.urlObject.application, 
                    col.urlObject.gridDataObject
                );
                url += "?" + resp;
            }
        }else{
            col.urlObject.urlFields.forEach((field:UrlField)=>{
                urlParams.push(field.urlParameter+field.operator+ row[field.valueField]);
            });
            url += "?" + urlParams.join("&");
        }
        window.open(url, '_blank', "noreferer=true,noopener=true,popup=false");
    }

    function checkCompareField(compareField: string, row: any) {
        return Object.keys(row).includes(compareField) ? row[compareField] : compareField;
    }

    function evaluateCondition(type: string | undefined, leftField: any, comparison: string,  rightField: any): boolean {
        let parsedRightField: number;
        switch(type) {
            case 'number':
                parsedRightField = parseFloat(rightField);
                return isNaN(parsedRightField) ? false : compareCondition(leftField, comparison, parsedRightField);
            case 'date':
                return compareCondition(Date.parse(leftField), comparison, Date.parse(rightField));
            case 'string':
                return compareText(leftField, comparison, rightField);
            default:
                return false;
        }
    }

    function compareText(leftField: string, comparison: string,  rightField: string): boolean {
        switch(comparison) {
            case '==':
                return leftField == rightField;
            case '!=':
                return leftField != rightField;
            case 'LIKE':
                return leftField.includes(rightField);
            default:
                return false;
        }
    }

    function compareCondition(leftField: number | Date, comparison: string,  rightField: number | Date): boolean {
        switch(comparison) {
            case '>':
                return leftField > rightField;
            case '>=':
                return leftField >= rightField;
            case '<':
                return leftField < rightField;
            case '<=':
                return leftField <= rightField;
            case '==':
                return leftField == rightField;
            case '!=':
                return leftField != rightField;
            default: 
                return false;
        }
    }

    function customAggregation(pData, field){
        if (!pData || pData.length === 0) {
            return Promise.resolve(null);
        }

        const returnFunction = (data, field) => {
            
            return "";
        }

        const result = returnFunction(pData, field) ?? null;

        return Promise.resolve(result);
    }

    type UrlField = {
        "urlParameter": string,
        "operator": string,
        "valueField": string,
    }
</script>